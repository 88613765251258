<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
            v-if="!pageLoading"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-select :items="status" label="Status" outlined></v-select>
              </v-col>

              <v-col cols="3" class="submitbtn">
                <v-btn class="submitbutton btn-dark" @click="filterAction">
                  Filter Category
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Title</th>
                  <th>Status</th>
                  <th class="text-right">Action</th>
                </tr>
              </thead>
              <tbody v-if="!pageLoading">
                <tr
                  v-for="(item, index) in allVideoCategory.results.list"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td>
                    <span
                      class="status"
                      :style="{
                        'background-color':
                          item.status === true ? 'green' : 'red',
                      }"
                      >{{ item.status === true ? "Active" : "Inactive" }}</span
                    >
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="`/dashboard/videoCategory/add/${item.slug}`"
                    >
                      <v-icon class="icons-action"> mdi-pencil </v-icon>
                    </router-link>
                    <v-icon
                      class="icons-action"
                      @click="() => deleteCategoryAct(item._id)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VideoCategory",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Video Category Listing",
      addlink: "/dashboard/videoCategory/add",
    },
    status: ["Active", "Inactive"],
    title: "",
    adsposition: "",
    valid: true,
    limit: 100,
    skip: 1,
    id: undefined,
    loading: false,
    pageLoading: true,
  }),
  methods: {
    ...mapActions(["fetchCategoryVideo", "deleteCategory"]),
    async filterAction() {
      await this.fetchCategoryVideo({
        limit: this.limit,
        skip: this.skip,
        categoryType: "video",
        title: this.title,
        status: this.status,
      });
    },
    nextPage(data) {
      this.fetchCategoryVideo({
        limit: this.limit,
        skip: data,
      });
    },
    prevPage(data) {
      this.fetchCategoryVideo({
        limit: this.limit,
        skip: data,
      });
    },
    async deleteCategoryAct(id) {
      if (confirm("Are you sure you want to delete this category?")) {
        this.loading = true;
        this.id = id;
        await this.deleteCategory(id);
        await this.fetchCategoryVideo({
          limit: this.limit,
          skip: this.skip,
          categoryType: "video",
        });
        this.loading = false;
        this.id = undefined;
      }
    },
  },

  computed: {
    ...mapGetters(["allVideoCategory", "allLoadingCurd"]),
  },
  async created() {
    await this.fetchCategoryVideo({
      limit: this.limit,
      skip: this.skip,
      categoryType: "video",
    });
    this.pageLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.filtertitle {
  margin-bottom: 10px;
}
.title {
  font-size: 1.875rem !important;
  font-weight: bold;
  margin: 10px 0 0 10px;
}

.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}
.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.submitbtn {
  margin-top: 10px;
  .submitbutton {
    padding: 28px 20px;
  }
}
.status {
  display: inline-block;
  padding: 5px 20px;
  color: white;
  width: 100px;
  text-align: center;
  border-radius: 15px;
  font-weight: bold;
}
</style>
